import './footer.scss';
import logo from '../../assets/logo.png';
import insta from '../../assets/Instagram.png';
import facebook from '../../assets/Twitter.png';
import whatsapp from '../../assets/whatsapp.png';
import impress from '../../assets/Legalnotice.pdf';

const socialMediaLinks = [
	{ src: insta, alt: 'Instagram', url: 'https://www.instagram.com/kadambafoods/' },
	{ src: facebook, alt: 'Twitter', url: 'https://www.facebook.com/KadambaFoods' },
	{ src: whatsapp, alt: 'WhatsApp', url: 'https://wa.me/4915566097538' }
];

const SocialMediaIcons = () => (
	<div className='d-flex justify-content-center'>
		{socialMediaLinks.map(({ src, alt, url }, index) => (
			<a
				href={url}
				key={index}
				aria-label={alt}
				className='mx-2'
				target='blank'
			>
				<img
					src={src}
					alt={alt}
					className='img-fluid'
				/>
			</a>
		))}
	</div>
);

const Footer = () => {
	return (
		<section className='footer__section py-3'>
			<div className='container-fluid px-md-4'>
				<div className='row align-items-center'>
					<div className='col-md-4 d-flex align-items-center'>
						<img
							src={logo}
							alt='logo'
							className='img-fluid'
							style={{ width: '100px' }}
						/>
						<span className='text-white'>
							Bringing India's Best to Your Desk: Unmatched Flavor, Delivered to You!
						</span>
					</div>
					<div className='col-md-4 text-center mt-md-0 mt-3'>
						<p className='text-white fw-bold'>Follow Us</p>
						<SocialMediaIcons />
					</div>
					<div className='col-md-4 mt-md-0 mt-3'>
						<p className='text__green fw-bold text-md-end text-center'>
							<a
								href={impress}
								target='_blank'
								rel='noopener noreferrer'
								className='text__green text-decoration-none'
							>
								IMPRESSUM DATENSCHUTZ
							</a>
						</p>

						<p className='text__orange text-md-end text-center'>
							Terms and Conditions | Privacy Policy | Cookies Policy
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
