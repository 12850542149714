import { useEffect, useMemo, useState } from 'react';
import logo from '../../assets/logo.png';
import './navbar.scss';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import profile from '../../assets/profile.png';
import cartIcon from '../../assets/cart.png';
import orderIcon from '../../assets/order.png';
import useInterval from '../../hooks/use-interval';
import { CartType, UserType } from '../../types';
import { getCartData } from '../../config/firestore/cartData';
import { DocumentData } from 'firebase/firestore';
import { getUserData, userExists } from '../../config/firestore/userData';
import { useSnackbar } from 'notistack';

const navLinks = [
	{ name: 'Home', url: '/' },
	{ name: 'About Us', url: '/#about-us' },
	{ name: 'Our Service', url: '/#our-services' },
	{ name: 'Benefits', url: '/#benefits' },
	{ name: 'Menu', url: '/menu' },
	{ name: 'Subscription', url: '/subscription' },
	{ name: 'Contact Us', url: '/contact-us' }
];

const dropDownItems = [
	{
		name: 'Profile',
		url: '/profile',
		img: profile
	},
	{
		name: 'Orders',
		url: '/orders',
		img: orderIcon
	},
	{
		name: 'Logout',
		url: '/logout'
	}
];

const Navbar = () => {
	const { pathname, hash } = useLocation();
	const { enqueueSnackbar } = useSnackbar();
	const { isAuthenticated, user } = useSelector((state: any) => state.otp);
	const [cartData, setCartData] = useState<CartType | DocumentData | null>([]);
	const [userData, setUserData] = useState<UserType | DocumentData | null>(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const fetchCartData = async () => {
		const userJson = JSON.parse(user || '{}');
		const cartData = await getCartData(userJson.uid);
		setCartData(cartData);
		const userData = await getUserData(userJson.uid);
		setUserData(userData);
	};

	const checkUserExists = async () => {
		const userJson = JSON.parse(user || '{}');
		const exists = await userExists(userJson.uid);
		if (!exists) {
			dispatch(logout());
			enqueueSnackbar('User not found, Kindly Login', { variant: 'info' });
			navigate('/login');
		}
	};

	useInterval(() => {
		if (user) {
			checkUserExists();
		}
	}, 1000);

	useInterval(() => {
		if (user) {
			fetchCartData();
		} else {
			setCartData([]);
		}
	}, 800);

	useEffect(() => {
		const id = hash.replace('#', '');
		if (id) {
			setTimeout(() => {
				const element = document.getElementById(id);
				if (element) {
					const offset = 70;
					const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
					window.scrollTo({ top: elementPosition, behavior: 'smooth' });
				}
			}, 100);
		}
	}, [hash]);

	const isActiveLink = useMemo(
		() => (linkUrl: string) => {
			return (
				(linkUrl === '/' && pathname === '/' && !hash) ||
				(linkUrl.startsWith('/#') && pathname === '/' && `#${linkUrl.split('/#')[1]}` === hash) ||
				(!linkUrl.startsWith('/#') && pathname.includes(linkUrl) && linkUrl !== '/')
			);
		},
		[pathname, hash]
	);

	const handleLogout = () => {
		dispatch(logout());
		enqueueSnackbar('Logout successful', { variant: 'success' });
		navigate('/login');
	};
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => setIsOpen(!isOpen);
	return (
		<>
			<nav className='navbar navbar-expand-lg nav__section fixed-top'>
				<div className='container-fluid'>
					<a
						className='navbar-brand'
						href='/'
					>
						<img
							src={logo}
							alt='logo'
							className='img-fluid logo'
						/>
					</a>
					{isAuthenticated && (
						<button
							className='btn cart__btn d-md-none d-flex'
							onClick={() => navigate('/cart')}
						>
							<img
								src={cartIcon}
								alt='Cart'
								className='cart-icon img-fluid'
							/>
							<span className='cart-text'>Cart</span>

							{cartData && cartData.totalQuantity > 0 && (
								<span className='cart-quantity'>{cartData?.totalQuantity}</span>
							)}
						</button>
					)}

					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='toggler-icon'></span>
						<span className='toggler-icon'></span>
						<span className='toggler-icon'></span>
					</button>

					<div
						className='collapse navbar-collapse'
						id='navbarSupportedContent'
					>
						<ul className='navbar-nav ms-auto mb-2 mb-lg-0 align-items-baseline'>
							{navLinks.map((link, index) => (
								<li
									className='nav-item'
									key={index}
								>
									<a
										className={`nav-link ${
											isActiveLink(link.url) ? 'active' : 'text__orange'
										} fw-bold`}
										href={link.url}
									>
										{link.name}
									</a>
								</li>
							))}
							<li className='nav-item'>
								{isAuthenticated ? (
									<>
										<div className='order_btn_profile'>
											<button
												className='btn cart__btn mx-3'
												onClick={() => navigate('/cart')}
											>
												<img
													src={cartIcon}
													alt='Cart'
													className='cart-icon img-fluid'
												/>
												<span className='cart-text'>Cart</span>

												{cartData && cartData.totalQuantity > 0 && (
													<span className='cart-quantity'>{cartData?.totalQuantity}</span>
												)}
											</button>
											<div className='dropdown'>
												<button
													className='btn btn-secondary dropdown-toggle bg-transparent border-0'
													type='button'
													id='dropdownMenuButton'
													aria-expanded={isOpen}
													onClick={toggleDropdown}
												>
													<img
														src={userData?.photoURL || profile}
														alt='profile'
														className='rounded-circle'
														style={{ width: 40, height: 40 }}
													/>
												</button>
												<ul
													className={`dropdown-menu${isOpen ? ' show end-0 mt-1' : ''} dropdown-menu-start`}
													aria-labelledby='dropdownMenuButton'
												>
													{dropDownItems.map((item, index) =>
														item.name === 'Logout' ? (
															<li key={index}>
																<button
																	className='dropdown-item btn cart__btn w-100 mx-0 my-2'
																	onClick={handleLogout}
																>
																	<span className='order-text'>{item.name}</span>
																</button>
															</li>
														) : (
															<li key={index}>
																<a
																	className='dropdown-item p-0 mt-1'
																	href={item.url}
																>
																	<img
																		src={item.img}
																		alt={item.img}
																		className='img-fluid mx-2'
																		style={{ width: '32px' }}
																	/>
																	{item.name}
																</a>
																<hr className='text-white'></hr>
															</li>
														)
													)}
												</ul>
											</div>
										</div>
									</>
								) : (
									<button
										className='btn cart__btn'
										onClick={() => navigate('/login')}
									>
										<img
											src={orderIcon}
											alt='Order'
											className='order-icon img-fluid'
										/>
										<span className='order-text'>Pre-order Now</span>
									</button>
								)}
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
