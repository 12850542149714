import './Truck.scss';
import truckImage from '../../assets/truck.png';

const Truck = () => {
	return (
		<section className='truck__section h-100'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col-md-12 p-0 position-relative h-100'>
						<img
							src={truckImage}
							alt='Background'
							className='img-fluid trackImage'
						/>
						<div className='text-overlay position-absolute top-0 left-0 right-0 d-flex align-items-start justify-content-center pt-5'>
							<div className='col-md-8'>
								<h1 className='fw-bold text__black text-center'>
									Experience Culinary Excellence Every Noon with
									<span className='text__green'> Kadamba's</span> Lunch Selections
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Truck;
