import './our-services.scss';
import { TagPrimary } from '../../Common/Tags';
import tava from '../../assets/tava.jpg'

const OurServices = () => {
	return (
		<section
			className='services__section'
			id='our-services'
		>
			<div className='container-fluid h-100'>
				<div className='row d-flex align-items-center h-100'>
					<div className='col-md-5 offset-md-1 mt-md-0 mt-5'>
						<TagPrimary />
						<h1 className='text-white mt-3'>TRUE EXPERTS AT WORK</h1>
						<h2 className='text__orange'>ONLY THE BEST FOR YOUR EVENTS</h2>
						<p className='text__gray__three'>
							Indulge your guests in a culinary experience they won’t forget! Elevate your events with
							​our exquisite catering service, where every dish is a masterpiece crafted with passion and
							​precision. Let us tailor a menu to your unique preferences, ensuring a gastronomic ​journey
							that suits your event perfectly. Enjoy the flavours, and let us handle the details. ​Get in
							touch for a personalised offer that combines great vegan and vegetarian dishes ​with
							affordable pricing. Your extraordinary event begins with a simple message – let’s ​create
							unforgettable moments together!
						</p>
					</div>
					<div className='col-md-6'>
						<img
							src={tava}
							alt='cooking'
							className='img-fluid'
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurServices;
