import './Tags.scss';

type TagGreenProps = {
	title: string;
};

export const TagGreen: React.FC<TagGreenProps> = ({ title }) => {
	return <button className='tags__green'>{title}</button>;
};

export const TagPrimary = () => {
	return <button className='tags__primary'>OUR SERVICES</button>;
};
