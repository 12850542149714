import React from 'react';
import './Button.scss';
import orderIcon from '../assets/order.png';
import { useNavigate } from 'react-router-dom';

const Button = () => {
	let navigate = useNavigate();
	const handleClick = () => {
		navigate('/menu');
	};
	return (
		<button
			className='btn order__btn'
			onClick={handleClick}
		>
			<img
				src={orderIcon}
				alt='Order'
				className='order-icon img-fluid'
			/>
			<span className='order-text'>Pre-order Now</span>
		</button>
	);
};

export default Button;
