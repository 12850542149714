import Button from '../../Common/Button';
import './Hero.scss';

const Hero = () => {
	return (
		<section className='hero__section'>
			<div className='container-fluid h-100'>
				<div className='row h-100 d-flex align-items-center'>
					<div className='col-md-6 offset-md-1'>
						<p className='text__gray mb-0'>FRESH . LOCAL . GOODNESS</p>
						<h1 className='text-white fw-bold'>
							Your <span className='text__green'>Food Service</span> From{' '}
							<span className='text__orange'>Berlin</span>
						</h1>
						<p className='text__gray'>
							Experience the taste of Berlin with our food service, offering a delectable selection of
							traditional and contemporary dishes. From classic Indian cuisine to international flavors,
							we cater to all tastes and occasions. Let us bring the flavors of Berlin to your next event,
							creating a memorable culinary experience for you and your guests
						</p>
						<Button />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
