
import { ContactCard, PaymentCard } from '../../Common/ContactCard';
import './Payment.scss';

const Payment = () => {
	return (
		<section className='payment__section'>
			<div className='container'>
				<div className='row'>
					<h1 className='col-md-12 text-white fw-bold text-center text-uppercase'>Contact us</h1>
					<p className='text__gray__three text-center'>
						We want to hear from you. Let us know how we can help.
					</p>
				</div>
				<div className='row justify-content-center'>
					<div className='col-md-10'>
						<PaymentCard />
						<ContactCard />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Payment
