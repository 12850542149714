import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

interface OTPState {
	showComp: boolean;
	success: boolean;
	phoneNumber: string | null;
	user: User | undefined | string | null;
	isAuthenticated: boolean;
	userExisted: boolean;
}

const initialState: OTPState = {
	showComp: true,
	success: false,
	phoneNumber: null,
	user: null,
	isAuthenticated: false,
	userExisted: false
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		addUserDetails: (state, action: PayloadAction<User | string>) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		logout: state => {
			state.isAuthenticated = false;
			state.user = null;
		}
	}
});

export const { addUserDetails, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
