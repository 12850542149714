import React from 'react';
import { TagGreen } from '../../Common/Tags';
import './Contact.scss';
import contactIcon from '../../assets/contact.svg';
import phoneIcon from '../../assets/call.png';
import emailIcon from '../../assets/sms.png';
// import locationIcon from "../../assets/location.png";
import websiteIcon from '../../assets/global.png';

type ContactInfoItemProps = {
	icon: string;
	text: string;
};

const ContactInfoItem: React.FC<ContactInfoItemProps> = ({ icon, text }) => (
	<div className='col-12 d-flex align-items-center mb-3'>
		<img
			src={icon}
			alt=''
			className='img-fluid me-3'
		/>
		<span>{text}</span>
	</div>
);

const Contact = () => {
	const contactInfo = [
		{ icon: phoneIcon, text: '+49 157 30443678' },
		{ icon: emailIcon, text: 'hello@kadamba-foods.com' },
		// { icon: locationIcon, text: "Berliner Allee 209 13088 Berlin" },
		{ icon: websiteIcon, text: 'www.kadamba-foods.com' }
	];

	return (
		<section className='contact__section'>
			<div className='container'>
				<div className='row justify-content-center mt-md-0 mt-5'>
					<div className='col-md-10 text-center'>
						<TagGreen title='CONTACT US' />
						<h1 className='text__black fw-bold mt-3'>ORDER WITH KADAMBA FOODS, TODAY</h1>
						<p className='text__gray__secondary mt-3 mb-5'>
							Discover a world of delectable delights with our exceptional vegan and vegetarian catering
							in Berlin! Immerse your events in the flavors of plant-based perfection, where each dish is
							a celebration of fresh ingredients.
						</p>
					</div>
					<div className='col-md-6 mt-5'>
						<div className='card border-0'>
							<div className='card-body'>
								<div className='row justify-content-center'>
									<div className='col-md-8 col-9'>
										<p className='text__green fw-bold'>Kadamba Foods</p>
										<div className='row mt-5'>
											{contactInfo.map(info => (
												<ContactInfoItem
													key={info.text}
													icon={info.icon}
													text={info.text}
												/>
											))}
										</div>
									</div>
									<div className='col-md-4 col-3 d-flex justify-content-center'>
										<img
											src={contactIcon}
											alt='contact'
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
