import './about-us.scss'; 
import about from '../../assets/about.png';
import { TagGreen } from '../../Common/Tags';

const AboutUs = () => {
	return (
		<section
			className='about__section'
			id='about-us'
		>
			<div className='container-fluid'>
				<div className='row d-flex align-items-center'>
					<div
						className='col-12 col-md-6 p-0 d-flex align-items-center justify-content-center'
						style={{ overflow: 'hidden' }}
					>
						<img
							src={about}
							alt='about'
							className='img-fluid'
						/>
					</div>
					<div className='col-12 col-md-5 ms-md-4 mt-md-0 mt-5'>
						<TagGreen title='ABOUT US' />
						<h1 className='mt-3 fw-bold'>INDULGE IN PLANT-BASED PERFECTION</h1>
						<p className='text-gray-secondary'>
							Discover a world of delectable delights with our exceptional vegan and vegetarian catering
							in Berlin! Immerse your events in the flavours of plant-based perfection, where each dish is
							a celebration of fresh ingredients. From vibrant salads to mouth-watering mains and aromatic
							desserts, our culinary expertise ensures a dining experience that delights even the most
							discerning palates. Elevate your gatherings with the best in vegan and vegetarian cuisine.
							Contact us now to bring a touch of culinary transcendence to your event – because in Berlin,
							we’re not just catering; we’re crafting plant-based memories that leave a lasting taste.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
