import { createBrowserRouter, BrowserRouterProps } from 'react-router-dom';
import MainLayout from '../layout/main-layout';
import Login from '../pages/login/login';
import AllOrders from '../pages/all-orders/all-orders';
import Profile from '../pages/profile/profile';
import Orders from '../pages/Orders/Orders';
import Completion from '../components/completion/completion';
import Subscription from '../pages/subscription/subscription';
import Cart from '../pages/cart/cart';
import Home from '../pages/home/home';
import Menu from '../pages/menu/menu';
import Payment from '../components/payment/Payment';

const router: BrowserRouterProps = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/',
				element: <Home />
			},
			{
				path: '/login',
				element: <Login />
			},
			{
				path: '/all-orders',
				element: <AllOrders />
			},
			{
				path: '/profile',
				element: <Profile />
			},
			{
				path: '/orders',
				element: <Orders />
			},
			{
				path: '/completion',
				element: <Completion />
			},
			{
				path: '/subscription',
				element: <Subscription />
			},
			{
				path: '/menu',
				element: <Menu />
			},
			{
				path: '/cart',
				element: <Cart />
			},
			{
				path: '/contact-us',
				element: <Payment />
			}
		]
	}
]);

export default router;
