import React from 'react';
import { TagGreen } from '../../Common/Tags';
import './Benefits.scss';
import cookOne from '../../assets/cook1.svg';
import cookTwo from '../../assets/cook2.svg';
import cookThree from '../../assets/cook3.svg';
import cookFour from '../../assets/cook4.svg';
import cookFive from '../../assets/cook5.svg';
import cookSix from '../../assets/cook6.svg';
import { BenefitsCard, BenefitsCardCenter } from './benefits-card';

const Benefits = () => {
	return (
		<section
			className='benefits d-flex align-items-center'
			id='benefits'
		>
			<div className='container'>
				<div className='row justify-content-center mt-md-0 mt-5'>
					<div className='col-md-10 text-center'>
						<TagGreen title='KADAMBA BENEFITS' />
						<h1 className='text__black fw-bold mt-3'>REASONS TO CHOOSE US FOR YOUR ​CATERING NEEDS</h1>
						<p className='text__gray__secondary'>
							Discover a world of delectable delights with our exceptional vegan and vegetarian ​catering
							in Berlin! Immerse your events in the flavours of plant-based perfection, where ​each dish
							is a celebration of fresh ingredients.logo
						</p>
					</div>
					<div className='col-md-12 mt-5'>
						<div className='row'>
							<div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCard
										title='Exceptional Culinary Expertise'
										description='Our catering service offers a diverse and expertly crafted menu, showcasing ​a range of delicious dishes that cater to various tastes and dietary preferences and requirements.'
										imageUrl={cookOne}
										background='#FEF6EB'
									/>
								</div>
								<div className='row flex-grow-1 mt-4'>
									<BenefitsCard
										title='Customization Options'
										description='We provide personalized experiences, allowing our clients to tailor menus and ​services to suit the unique requirements and themes of your events.'
										imageUrl={cookThree}
										background='#F1F6FC'
									/>
								</div>
							</div>
							<div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCardCenter
										title='High-Quality Ingredients'
										description='Our catering service offers a diverse and expertly crafted menu, showcasing ​a range of delicious dishes that cater to various tastes and dietary preferences and requirements.'
										imageUrl={cookFive}
										imageUrlTwo={cookSix}
										background='#F6FCF1'
									/>
								</div>
							</div>
							<div className='col-md-4 d-flex flex-column'>
								<div className='row flex-grow-1'>
									<BenefitsCard
										title='Impeccable Presentation'
										description='You can expect not only delectable flavours but also visually stunning ​presentations, adding an extra touch of elegance to any of your events or gatherings.'
										imageUrl={cookTwo}
										background='#F1F1F1'
									/>
								</div>
								<div className='row flex-grow-1 mt-4'>
									<BenefitsCard
										title='Professional Service'
										description='Our team’s professionalism ensures that events run smoothly with attentive and ​courteous staff who contribute to a positive and enjoyable dining experience.'
										imageUrl={cookFour}
										background='#F5F1FC'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Benefits;
