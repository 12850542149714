import AboutUs from "../../components/about-us/about-us";
import Benefits from "../../components/benefits/Benefits";
import Contact from "../../components/contact/Contact";
import Hero from "../../components/hero/Hero";
import OurServices from "../../components/our-services/our-services";
import Truck from "../../components/truck/Truck";


const Home = () => {
	return (
		<div className='App'>
			<Hero />
			<AboutUs />
			<OurServices />
			<Benefits />
			<Contact />
			<Truck />
		</div>
	);
};

export default Home;
