import moment from 'moment';

export function getMondayToFridayDates(weekIndex: number): string {
	const startDate = moment().startOf('isoWeek').add(weekIndex, 'weeks');
	const endDate = startDate.clone().add(4, 'days');
	return `(${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')})`;
}

export function weekDates(weekNumber: number, dayNumber: number) {

	// we need to substract the value fromm dayNumber such that when the previous month ends on 5th week, we calculate from start of the 5th week till the end of the month, and those number of days we subsctract from the dayNumber

	// get previous month's last 5th week days count
	const previousMonth = moment().subtract(1, 'months');
	const previousMonthLastWeek = previousMonth.endOf('month').week();
	const previousMonthLastWeekDays = previousMonth.weekday();
	const daysToSubstract = previousMonthLastWeekDays - 1;



	return moment()
		.startOf('month')
		.add(weekNumber, 'weeks')
		.add(dayNumber - previousMonthLastWeekDays, 'days')
		.format('DD MMM YYYY')
		.toUpperCase();
}

export function getTodayDay() {
	return moment().format('DD MMM YYYY').toUpperCase();
}

export function getFutureDate(dayNumber: number) {
	if (dayNumber <= 0) return getTodayDay();
	return moment().add(dayNumber, 'days').format('DD MMM YYYY').toUpperCase();
}
